import React, { useEffect } from "react";

import { Grid, Typography, Box } from "@mui/material";
import MyTable from "../../components/Table";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Spinner from "../../components/Spinner";

const VENDOR_URL = "/request-vendorInfo";

const headers = [
  { header: "S/N", field: "sn" },
  { header: "User Name", field: "vendorName" },
  { header: "Address", field: "vendorAddress" },
];

export const tableStyle = {
  justifyContent: "sapce-between",
  width: "100%",
};

export const iconStyle = {
  fontSize: "2.5rem",
  border: "1px solid rgb(189, 195, 201)",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 1.2px 5.68px 0px",
  color: "rgb(189, 195, 201)",
  borderRadius: "10px",
  padding: "0.313rem",
};

const AccountVerificationRequest = () => {
  const [response, errorMessage, loading, axiosFetch] = useAxios();

  const getData = async () => {
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: VENDOR_URL,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const rows = response;

  const rowData = [];
  rows.map((row, index) => {
    row["sn"] = index + 1;
    rowData.push(row);
  });

  const tableData = { headers, rowData };

  return (
    <Grid container direction="column" sx={{ paddingY: "2.5rem" }}>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "left",
          width: "100%",
        }}
      >
        <Typography
          align="left"
          variant="h5"
          component="h5"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          Verification Requests
        </Typography>
      </Grid>
      <Grid item>
        {loading && <Spinner text="Fetching verfication request..." />}
        {response.length > 0 ? (
          <Box sx={{ position: "relative" }}>
            <MyTable
              tableData={tableData}
              link="/admin/request-vendorInfo"
              style={tableStyle}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              marginTop: "20%",
              justifyContent: "center",
              flex: 1,
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <AccountCircleOutlinedIcon sx={iconStyle} />

            <Typography
              component="h6"
              variant="h6"
              style={{
                textAlign: "center",
                alignContent: "center",
                fontWeight: "600",
                fontSize: "1.46vm",
              }}
            >
              No available request
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AccountVerificationRequest;
