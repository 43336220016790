import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { colors } from "../utils/DefaultStyle";
import { Typography } from "@mui/material";

function ListItems({ icon, text, name }) {
  return (
    <ListItem sx={{ display: "flex", alignItems: "center" }}>
      {icon && (
        <ListItemIcon
          style={{
            minWidth: "40px",
            color: colors.chocolateBlack,
            fontWeight: "300",
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      {name && (
        <Typography
          sx={{
            color: colors.chocolateBlack,

            fontSize: "0.9rem",
            width: "40px",
            marginTop: "0.26rem",
            marginBottom: "0.25rem",
          }}
        >
          {name}
        </Typography>
      )}

      {text && (
        <Typography
          sx={{
            color: colors.chocolateBlack,

            fontSize: "0.9rem",
            fontWeight: 400,
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
          }}
        >
          {text}
        </Typography>
      )}
    </ListItem>
  );
}
export default ListItems;
