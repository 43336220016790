import React from "react";

import {
  CardHeader,
  Card,
  Typography,
  Box,
  Avatar,
  CardMedia,
  Modal,
} from "@mui/material";
import styled from "@emotion/styled";

import List from "@mui/material/List";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import { styleDisplay } from "./MediaCard";
import PrismaZoom from "react-prismazoom";

import Commonbutton from "./CommonButton";

import { headerStyle, headerTextStyle } from "./PlainCard";

import ListItems from "./ListItems";

const StyledProfileCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    borderRadius: "0.5rem",
    backgroundColor: "#fefefe",
    display: "flex",
    flexDirection: "column",
    boxShadow: 5,
    padding: "0.7rem",
  })
);

const nameStyle = {
  fontWeight: "bold",
  color: "#4B4B4B",
  fontSize: "1.2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginTop: "0.5rem",
};

const styledImage = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  objectFit: "cover",
  cursor: "pointer",
  borderRadius: "0.5rem",
  border: "1px solid #f9f9f9",
};

const styledAvatar = {
  height: "10rem",
  width: "10rem",
  margin: "10px auto",
  display: "flex",
  justifyContent: "center",
};

const docCommonButtonStyle = {
  fontSize: "0.9rem",
  margin: "0.5rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  borderRadius: 25,
  width: "fit-content",
  padding: "0.2rem 1.2rem",
  height: "unset",
};

function ProfileCard(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    img,
    name,
    url,
    title,
    vendorSlug,
    vendorAddress,
    idCardNum,
    vendorPhone,
    email,
    bankAttest,
    merchDocs,
  } = props;
  return (
    <StyledProfileCard>
      {title && (
        <CardHeader
          sx={headerStyle}
          title={
            <Typography variant="body1" sx={headerTextStyle}>
              {title}
            </Typography>
          }
        />
      )}

      <Avatar variant="rounded" sx={styledAvatar}>
        <CardMedia
          component="img"
          height="120"
          image={img}
          alt="Profile Photo"
          sx={styledImage}
          onClick={handleOpen}
        />
      </Avatar>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleDisplay}>
          <PrismaZoom maxZoom={4} minZoom={1}>
            <img src={img} alt="profile" width="100%" height="100%" />
          </PrismaZoom>{" "}
        </Box>
      </Modal>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography component="h4" sx={nameStyle}>
            {name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            {bankAttest && (
              <Commonbutton
                variant="text"
                color="primary"
                btnText="Attestation"
                href={bankAttest}
                target="_blank"
              />
            )}
            {url && (
              <Commonbutton
                variant="contained"
                color="primary"
                btnText="Vendor Docs"
                sx={docCommonButtonStyle}
                href={url}
                target="_blank"
              />
            )}
            {merchDocs && (
              <Commonbutton
                variant="text"
                color="primary"
                btnText="Merchant Docs"
                href={merchDocs}
                target="_blank"
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <List sx={{ paddingTop: "0px" }}>
            {vendorAddress && (
              <ListItems
                icon={<LocationOnOutlinedIcon sx={{ fontSize: "1rem" }} />}
                text={vendorAddress}
              />
            )}
            {vendorPhone && (
              <ListItems
                icon={<LocalPhoneOutlinedIcon sx={{ fontSize: "1rem" }} />}
                text={vendorPhone}
              />
            )}
            {email && (
              <ListItems
                icon={<EmailOutlinedIcon sx={{ fontSize: "1rem" }} />}
                text={email}
              />
            )}
            {vendorSlug && <ListItems name="Slug:" text={vendorSlug} />}
            {idCardNum && <ListItems name="NIC:" text={idCardNum} />}
          </List>
        </Box>
      </Box>
    </StyledProfileCard>
  );
}
export default ProfileCard;
