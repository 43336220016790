import React from "react";
import { StyledCard, itemStyle, innerBoxStyle, valueStyle } from "./PlainCard";
import { Box, Typography } from "@mui/material";

import { formatAmount, formateDate } from "../utils/helperFunctions";

const DetailsCard = (props) => {
  const {
    transferId,
    userId,
    amount,
    charges,
    medium,
    status,
    accBalance,
    createdAt,
    updatedAt,
  } = props;
  return (
    <StyledCard>
      <Box>
        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            Transfer Id
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {transferId}
          </Typography>
        </Box>

        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            Amount
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {formatAmount(amount)}
          </Typography>
        </Box>
        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            Charges
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {charges}
          </Typography>
        </Box>
        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            medium
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {medium}
          </Typography>
        </Box>
        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            Status
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {status}
          </Typography>
        </Box>
        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            Account Balance
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {formatAmount(accBalance)}
          </Typography>
        </Box>
        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            Created At{" "}
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {formateDate(createdAt)}
          </Typography>
        </Box>
        <Box sx={innerBoxStyle}>
          <Typography variant="body1" sx={itemStyle}>
            Updated At
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {formateDate(updatedAt)}
          </Typography>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default DetailsCard;
