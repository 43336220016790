import React from "react";
import { InputBase } from "@mui/material";
import styled from "@emotion/styled";
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.4, 0.4),
    paddingLeft: `calc(1.8em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

function Inputbase({ ...props }) {
  return <StyledInputBase {...props}></StyledInputBase>;
}

export default Inputbase;
