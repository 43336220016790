import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Box } from "@mui/material";

import { firstName } from "../../utils/helperFunctions";
import { capitalize, lowerCase } from "../../utils/helperFunctions";

import Commonbutton from "../../components/CommonButton";
import ProfileCard from "../../components/ProfileCard";
import PlainCard from "../../components/PlainCard";
import MediaCard from "../../components/MediaCard";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";
import Confirmationmodal from "../../components/Confirmationmodal";
import Rejectmodal from "../../components/Rejectmodal";

import {
  ButtonStyleDisplay,
  ButtonStyleDisplay2,
} from "../userActivation/AccountDetails";
import Alerts from "../../components/Alerts";
import Spinner from "../../components/Spinner";

function CardRequestDetails() {
  const { _id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [description, setDescription] = useState("");
  const [toSuccess, setToSuccess] = useState(false);

  const [response, errorMessage, loading, axiosFetch] = useAxios();

  const REJECT_CARD_URL = `/reject-card-request/${_id}`;
  const CARD_DETAILS_URL = `/card-request/${_id}`;
  const VALIDATE_CARD_URL = `/validate-card-request/${_id}`;

  const handleClose = () => {
    setOpenApprove(false);
    setOpenDecline(false);
  };

  const handleApproveClick = () => {
    setOpenApprove(true);
  };
  const handleDeclineClick = () => {
    setOpenDecline(true);
  };

  const handleApproveSubmit = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: VALIDATE_CARD_URL,
    });
    if (success) {
      setToSuccess(true);
      navigate("/admin/card-request");
    }
  };

  const handleDeclineSubmit = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: REJECT_CARD_URL,
      requestConfig: {
        description,
      },
    });
    if (success) {
      setToSuccess(true);
      navigate("/admin/card-request");
    }
  };

  const getData = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: CARD_DETAILS_URL,
    });

    return axiosFetch;
  };

  useEffect(() => {
    getData();
  }, []);

  const data = response;

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToSuccess(false);
    setOpen(false);
  };

  return (
    <>
      {loading && <Spinner text="Fetching data..." />}
      {toSuccess && (
        <Alerts
          severity="success"
          open={toSuccess}
          onClose={onClose}
          message={response.message}
        />
      )}
      {errorMessage && (
        <Alerts
          severity="error"
          open={open}
          onClose={onClose}
          message={errorMessage}
        />
      )}
      {
        <Grid container>
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={2}
              direction="row"
              sx={{
                justifyContent: "center",
                margin: " 10px auto",
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={11}
                direction="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: {
                      xs: "center",
                    },
                    justifyContent: {
                      xs: "center",
                      md: "space-between",
                    },
                    width: {
                      xs: "100%",
                    },
                  }}
                >
                  {data.personalInfo && (
                    <h2
                      style={{
                        fontSize: "2rem",
                      }}
                    >
                      {data.personalInfo.name
                        ? data.personalInfo.name &&
                          firstName(
                            capitalize(lowerCase(data.personalInfo.name))
                          )
                        : data.personalInfo.firstName
                        ? data.personalInfo.firstName &&
                          data.personalInfo.firstName
                        : null}
                      's Details
                    </h2>
                  )}
                </Box>
                <Box sx={ButtonStyleDisplay}>
                  <Commonbutton
                    variant="contained"
                    color="primary"
                    btnText="Approve"
                    sx={{ fontSize: "1rem", marginRight: "1.2rem" }}
                    onClick={handleApproveClick}
                  />
                  <Commonbutton
                    variant="outlined"
                    color="primary"
                    btnText="Decline"
                    sx={{ fontSize: "1rem" }}
                    onClick={handleDeclineClick}
                  />
                  {data.personalInfo && (
                    <Confirmationmodal
                      handleSubmit={handleApproveSubmit}
                      open={openApprove}
                      handleClose={handleClose}
                      text={`This action will grant ${
                        data.personalInfo.name
                          ? data.personalInfo.name && data.personalInfo.name
                          : data.personalInfo.firstName
                          ? data.personalInfo.firstName &&
                            data.personalInfo.firstName
                          : null
                      } access to international payments. Are you sure you want to proceed?`}
                    />
                  )}
                  {data.personalInfo && (
                    <Rejectmodal
                      handleSubmit={handleDeclineSubmit}
                      open={openDecline}
                      handleClose={handleClose}
                      text={`This action will reject ${
                        data.personalInfo.name
                          ? data.personalInfo.name && data.personalInfo.name
                          : data.personalInfo.firstName
                          ? data.personalInfo.firstName &&
                            data.personalInfo.firstName
                          : null
                      } access to international payments. Are you sure you want to proceed?`}
                      description={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                xs={12}
                md={4}
                sx={{
                  maxWidth: {
                    xs: "20.22rem",
                    md: "100%",
                  },
                }}
              >
                <ProfileCard
                  merchDocs={data.merchDocs && data.merchDocs}
                  bankAttest={data.bankAttest && data.bankAttest}
                  img={data.picture && data.picture}
                  name={data.personalInfo && data.personalInfo.name}
                  vendorAddress={data.personalInfo && data.personalInfo.address}
                  vendorPhone={data.personalInfo && data.personalInfo.phone}
                  idCardNum={data.personalInfo && data.personalInfo.idCardNum}
                  email={data.personalInfo && data.personalInfo.email}
                />
              </Grid>
              <Grid
                item
                lg={4}
                spacing={2}
                xs={12}
                md={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MediaCard img={data.idCardFront && data.idCardFront} />
                <MediaCard img={data.idCardBack && data.idCardBack} />
              </Grid>
              <Grid item lg={4} xs={12} md={12}>
                {data.businessInfo && (
                  <PlainCard
                    title="Business Information"
                    data={data.businessInfo}
                  />
                )}
              </Grid>
              <Box sx={ButtonStyleDisplay2}>
                <Commonbutton
                  variant="outlined"
                  color="primary"
                  btnText="Decline"
                  sx={{ fontSize: "1rem", marginRight: "1.2rem" }}
                  onClick={handleDeclineClick}
                />
                <Commonbutton
                  variant="contained"
                  color="primary"
                  btnText="Approve"
                  sx={{ fontSize: "1rem" }}
                  onClick={handleApproveClick}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
}

export default CardRequestDetails;
