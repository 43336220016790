import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Grid, Box } from "@mui/material";

import PlainCard from "../../components/PlainCard";
import ProfileCard from "../../components/ProfileCard";
import Commonbutton from "../../components/CommonButton";
import MediaCard from "../../components/MediaCard";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";
import { firstName } from "../../utils/helperFunctions";
import Confirmationmodal from "../../components/Confirmationmodal";
import Rejectmodal from "../../components/Rejectmodal";
import Alerts from "../../components/Alerts";
import Spinner from "../../components/Spinner";

export const ButtonStyleDisplay = {
  display: { xs: "none", md: "flex" },
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderColor: "primary",
};
export const ButtonStyleDisplay2 = {
  display: { xs: "flex", md: "none" },
  flexDirection: "row",
  justifyContent: "flex-end",
  borderColor: "primary",
  width: "100%",
  marginTop: "1.5rem",
};

const AccountDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [toSuccess, setToSuccess] = useState(false);

  const URL = `/request-vendorInfo/${_id}`;
  const VALIDATE_ACCOUNT_URL = `/validatevendor/${_id}`;
  const REJECT_ACCOUNT_URL = `/rejectvendor/${_id}`;

  const [response, errorMessage, loading, axiosFetch] = useAxios();
  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [description, setDescription] = useState("");

  const handleClose = () => {
    setOpenApprove(false);
    setOpenDecline(false);
  };

  const handleApproveClick = () => {
    setOpenApprove(true);
  };
  const handleDeclineClick = () => {
    setOpenDecline(true);
  };

  const handleApproveSubmit = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: VALIDATE_ACCOUNT_URL,
    });
    if (success) {
      setToSuccess(true);
      navigate("/admin/request-vendorInfo");
    }
  };

  const handleDeclineSubmit = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: REJECT_ACCOUNT_URL,
      requestConfig: {
        description,
      },
    });
    if (success) {
      setToSuccess(true);
      navigate("/admin/request-vendorInfo");
    }
  };

  const getData = async () =>
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: URL,
    });

  useEffect(() => {
    getData();
  }, []);

  const data = response;

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToSuccess(false);
    setOpen(false);
  };

  return (
    <>
      {loading && <Spinner text="Fetching data..." />}
      {toSuccess && (
        <Alerts
          severity="success"
          open={toSuccess}
          onClose={onClose}
          message={response.message}
        />
      )}

      {errorMessage && (
        <Alerts
          severity="error"
          open={open}
          onClose={onClose}
          message={errorMessage}
        />
      )}
      {
        <Grid
          container
          item
          xs={12}
          sm={12}
          spacing={2}
          direction="row"
          sx={{
            justifyContent: "center",
            margin: " 10px auto",
            marginTop: "2.5rem",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={11}
            direction="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: {
                  xs: "center",
                },
                justifyContent: {
                  xs: "center",
                  md: "space-between",
                },
                width: {
                  xs: "100%",
                },
              }}
            >
              <h2
                style={{
                  fontSize: "2rem",
                }}
              >
                {data.fullName && firstName(data.fullName)}'s Details
              </h2>
            </Box>
            <Box sx={ButtonStyleDisplay}>
              <Commonbutton
                variant="contained"
                color="primary"
                btnText="Approve"
                sx={{ fontSize: "1rem", marginRight: "1.2rem" }}
                type="submit"
                onClick={handleApproveClick}
              />
              <Commonbutton
                type="submit"
                variant="outlined"
                color="primary"
                btnText="Decline"
                sx={{ fontSize: "1rem" }}
                onClick={handleDeclineClick}
              />
              <Confirmationmodal
                handleSubmit={handleApproveSubmit}
                open={openApprove}
                handleClose={handleClose}
                text={`This action will validate ${
                  data.fullName && data.fullName
                }'s account. Are you sure you want to proceed?`}
              />
              <Rejectmodal
                handleSubmit={handleDeclineSubmit}
                open={openDecline}
                handleClose={handleClose}
                text={`This action will reject ${
                  data.fullName && data.fullName
                }'s account activation request. Are you sure you want to proceed?`}
                description={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Grid>{" "}
          <Grid
            item
            lg={3}
            xs={12}
            md={4}
            sx={{
              maxWidth: {
                xs: "20.22rem",
                md: "100%",
              },
            }}
          >
            <ProfileCard
              url={data.vendorDocs && data.vendorDocs}
              img={data.picture && data.picture}
              name={data.fullName && data.fullName}
              vendorAddress={data.vendorAddress && data.vendorAddress}
              vendorSlug={data.vendorSlug && data.vendorSlug}
              vendorPhone={data.vendorPhone && data.vendorPhone}
              idCardNum={data.idCardNum && data.idCardNum}
            />
          </Grid>
          <Grid
            item
            lg={4}
            spacing={2}
            xs={12}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MediaCard img={data.idCardFront && data.idCardFront} />
            <MediaCard img={data.idCardBack && data.idCardBack} />
          </Grid>
          <Grid item lg={4} xs={12} md={12}>
            {data.businessInfo && (
              <PlainCard
                title="Business Information"
                data={data.businessInfo}
              />
            )}
          </Grid>
          <Box sx={ButtonStyleDisplay2}>
            <Commonbutton
              type="submit"
              variant="outlined"
              color="primary"
              btnText="Decline"
              sx={{ fontSize: "1rem", marginRight: "1.2rem" }}
              onClick={handleDeclineClick}
            />
            <Commonbutton
              type="submit"
              variant="contained"
              color="primary"
              btnText="Approve"
              sx={{ fontSize: "1rem" }}
              onClick={handleApproveClick}
            />
          </Box>
        </Grid>
      }
    </>
  );
};

export default AccountDetails;
