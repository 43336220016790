import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import UnclickableTable from "../../components/UnclickableTable";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";
import Confirmationmodal from "../../components/Confirmationmodal";
import { useNavigate, useParams } from "react-router-dom";
import { iconStyle } from "../userActivation/AccountVerificationRequest";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import Spinner from "../../components/Spinner";
import { formatAmount, formateDate } from "../../utils/helperFunctions";
import Alerts from "../../components/Alerts";

const UNSETTLED_URL = "/unsettled-trans";
const SETTLED_URL = "/settle-trans";

const headers = [
  { header: "S/N", field: "sn" },
  { header: "Transfer ID", field: "transferId" },
  { header: "Transfer Type", field: "transType" },
  { header: "User Amount", field: "userAmt" },
  { header: "Time of Payment", field: "payTime" },
];

function UnsettledCardPayment() {
  const [open, setOpen] = useState(false);
  const [toSuccess, setToSuccess] = useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const navigate = useNavigate();
  const { transferId, userAmt } = useParams();

  const [response, errorMessage, loading, axiosFetch] = useAxios();
  const handleClose = () => {
    setOpen(false);
  };
  const handleApproveClick = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: SETTLED_URL,
      requestConfig: {
        transferId,
      },
    });
    if (success) {
      setToSuccess(true);
      navigate("/admin/payment");
    }
  };

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
    setToSuccess(false);
  };

  const getData = async () => {
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: UNSETTLED_URL,
    });

    return axiosFetch;
  };

  useEffect(() => {
    getData();
  }, []);

  const rows = response;
  const rowData = [];
  rows.map((row, index) => {
    row["payTime"] = `${formateDate(row.payTime)}`;
    row["userAmt"] = `${formatAmount(row.userAmt)}`;

    row["sn"] = index + 1;
    rowData.push(row);
  });
  const tableData = { headers, rowData };

  return (
    <Grid container direction="column" sx={{ paddingY: "2.5rem" }}>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "left",
          width: "100%",
        }}
      >
        <Typography
          align="left"
          variant="h5"
          component="h5"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          Unsettled International Payments
        </Typography>
      </Grid>

      <Grid item>
        {loading && <Spinner text="Fetching unsettled payments..." />}
        {toSuccess && (
          <Alerts
            severity="success"
            open={alertOpen}
            onClose={onClose}
            message={response.message}
          />
        )}

        {errorMessage && (
          <Alerts
            severity="error"
            open={alertOpen}
            onClose={onClose}
            message={errorMessage}
          />
        )}
        {response.length > 0 ? (
          <Box
            sx={{
              position: "relative",
            }}
          >
            <UnclickableTable
              tableData={tableData}
              hasButton="Settle"
              handleClick={handleApproveClick}
            />
            <Confirmationmodal
              open={open}
              handleClose={handleClose}
              text={`This action will credit this user's account. Are you sure you want to proceed?`}
              handleSubmit={handleSubmit}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              marginTop: "20%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CreditCardOffOutlinedIcon sx={iconStyle} />

            <Typography
              component="h6"
              variant="h6"
              style={{
                textAlign: "center",
                alignContent: "center",
                fontWeight: "600",
                fontSize: "1.4rem",
              }}
            >
              No available request
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default UnsettledCardPayment;
