import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../utils/DefaultStyle";

function MyTable({ tableData, link, style }) {
  const { headers, rowData: rows } = tableData;

  return (
    <TableContainer
      sx={{
        position: "absolute",
        maxHeight: 440,
      }}
    >
      <Table
        stickyHeader
        sx={[{ minWidth: 650, bgcolor: colors.tableColor }, style]}
      >
        <TableHead>
          <TableRow>
            {headers.map((h1, index) => (
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "17px",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                {h1.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((trow) => (
            <TableRow
              component={Link}
              to={`${link}/${trow._id}`}
              state={trow._id}
              hover
              sx={{
                textDecoration: "none",
              }}
              key={trow._id}
            >
              {headers.map((h1, index) => (
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "1rem",
                    padding: "12px",
                  }}
                  key={index}
                >
                  {trow[h1.field] ? trow[h1.field] : "-"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MyTable;
