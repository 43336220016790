import React from "react";
import { ThemeProvider } from "@mui/material";
import DefaultStyle from "./utils/DefaultStyle";
import AllRoutes from "./routes/AllRoutes";
function App() {
  return (
    <ThemeProvider theme={DefaultStyle}>
      <AllRoutes />
    </ThemeProvider>
  );
}

export default App;
