import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Box,
  Card,
  List,
  ListItem,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const Cardmodal = styled(Card)(({ theme }) => ({
  boxShadow: "0 1.2px 5.68px 0 rgb(0 0 0 / 10%)",
  minHeight: "10rem",
  minWidth: "35%",
  marginTop: "0.5rem",
  position: "absolute",
}));

function Searchmodal({ userlink, usersData, handleClose }) {
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Cardmodal>
        <CardContent>
          <Box sx={{ display: "flex" }}>
            {usersData.length === 0 ? (
              <Typography>No result found</Typography>
            ) : (
              <List>
                {usersData.map((user, index) => (
                  <ListItem
                    component={Link}
                    to={`${userlink}/${user._id}`}
                    state={user._id}
                    key={index}
                    sx={{ padding: "0.3rem" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "primary",
                        fontWeight: 400,
                      }}
                    >
                      {user.username}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </CardContent>
      </Cardmodal>
    </ClickAwayListener>
  );
}

export default Searchmodal;
