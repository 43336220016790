import { createTheme } from "@mui/material";
const DefaultStyle = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#eef2f5",
    },
    error: {
      main: "#a82e2e",
    },
    success: {
      main: "#198754",
    },
  },

  typography: {
    fontFamily: "Ubuntu, sans-serif",
  },
  shape: {
    borderRadius: 10,
  },

  components: {
    DataGrid: {
      styleOverrides: {
        row: {
          backgroundColor: "blue",
          color: "yellow",
          "&:hover": {
            backgroundColor: "blue",
          },
        },
      },
    },
  },
});

const colors = {
  tableColor: "#fefefe",
  backgroundColor: "#f4f4f4",
  chocolateBlack: "#1b1811",
  errorcolor: "#a82e2e",
  success: "#198754",
  background: "#fafafa",
};

const currency = "FCFA";

export default DefaultStyle;
export { colors, currency };
