import React, { useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";

import MyTable from "../../components/Table";
import { iconStyle } from "../userActivation/AccountVerificationRequest";
import Spinner from "../../components/Spinner";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { formatAmount, formateDate } from "../../utils/helperFunctions";

const BANK_REQUEST_URL = "/pending-cashout";

const headers = [
  { header: "S/N", field: "sn" },
  { header: "Amount", field: "amount" },
  { header: "Date", field: "createdAt" },
];

const BankTable = () => {
  const [response, errorMessage, loading, axiosFetch] = useAxios();

  const getData = () => {
    axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: BANK_REQUEST_URL,
    });

    return axiosFetch;
  };

  useEffect(() => {
    getData();
  }, []);
  const rows = response;

  const rowData = [];
  rows.map((row, index) => {
    row["amount"] = `${formatAmount(row.amount)}`;
    row["createdAt"] = `${formateDate(row.createdAt)}`;
    row["sn"] = index + 1;
    rowData.push(row);
  });
  const tableData = { headers, rowData };
  return (
    <Grid container direction="column" sx={{ paddingY: "2.5rem" }}>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "left",
          width: "100%",
        }}
      >
        <Typography
          align="left"
          variant="h5"
          component="h5"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          Bank Payout Requests
        </Typography>
      </Grid>
      {loading && <Spinner text="Fetching bank payout request..." />}
      {response.length > 0 ? (
        <Grid item>
          <Box sx={{ position: "relative" }}>
            <MyTable tableData={tableData} link={"/admin/pending-cashout"} />
          </Box>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            marginTop: "20%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AccountBalanceOutlinedIcon sx={iconStyle} />

          <Typography
            component="h6"
            variant="h6"
            style={{
              textAlign: "center",
              alignContent: "center",
              fontWeight: "600",
              fontSize: "1.4rem",
            }}
          >
            No available request
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default BankTable;
