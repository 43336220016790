import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import styled from "@emotion/styled";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Alerts({ ...props }) {
  return (
    <Snackbar autoHideDuration={5000} {...props}>
      <Alert {...props}>{props.message}</Alert>
    </Snackbar>
  );
}

export default Alerts;
