import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../utils/DefaultStyle";

import {
  ImageListItem,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";

import logo from "../assets/fapshi-logo.png";

const ItemList = [
  {
    text: " Account Verification Requests",
    path: "/admin/request-vendorInfo",
  },
  {
    text: "Bank Payout Requests",
    path: "/admin/pending-cashout",
  },
  {
    text: "Int'l Payment Requests",
    path: "/admin/card-request",
  },
  {
    text: "Unsettled Int'l Payments",
    path: "/admin/payment",
  },
];

const useStyles = {
  active: {
    background: colors.backgroundColor,
  },
};

function SideBar() {
  const [sidebarItem, setSidebarItem] = useState(null);
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <ImageListItem
          sx={{
            objectFit: "cover",

            ml: "1.2rem",
            mt: "25px",
            mb: "20px",
            width: "5rem",
          }}
        >
          <img src={logo} alt="logo" />
        </ImageListItem>
      </Box>

      <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        {ItemList.map((item) => (
          <NavLink
            to={item.path}
            style={{
              textDecoration: "none",
              color: "black",
              alignItems: "flex-start",
            }}
          >
            <ListItem
              sx={{
                paddingLeft: "1rem",
                alignItems: "flex-start",
              }}
              commonbutton
              key={item.text}
              onClick={() => setSidebarItem(item.text)}
            >
              <div style={{ color: "#3f51b5" }}>{item.icon}</div>
              <ListItemText
                sx={{ fontSize: "1rem", alignItems: "flex-start" }}
                primary={item.text}
                disableTypography
                disablePadding
              />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Box>
  );
}

export default SideBar;
