import React, { useEffect } from "react";

import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";
import { Box, Typography, Grid, CardContent } from "@mui/material";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import {
  StyledCard,
  innerBoxStyle,
  valueStyle,
  itemStyle,
} from "../../components/PlainCard";

import { formateDate, formateString } from "../../utils/helperFunctions";

const UserDetails = () => {
  const { _id } = useParams();

  const USER_URL = `/getuser/${_id}`;

  const [response, error, loading, axiosFetch] = useAxios();

  const getData = async () => {
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: USER_URL,
    });
  };

  useEffect(() => {
    getData();
  }, [_id]);

  const data = response;
  console.log(data);

  return (
    <>
      {loading && <Spinner text="Fetching data..." />}
      {
        <Grid container direction="column" sx={{ paddingY: "2.5rem" }}>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "left",
              width: "100%",
            }}
          >
            <Typography
              align="left"
              variant="h5"
              component="h5"
              sx={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              User Details
            </Typography>
          </Grid>
          <Grid item>
            <StyledCard elevation={2}>
              <Box sx={innerBoxStyle}>
                <Typography variant="body1" sx={itemStyle}>
                  User Name
                </Typography>
                {data.username && (
                  <Typography variant="body1" sx={valueStyle}>
                    {data.username}
                  </Typography>
                )}
              </Box>
              <Box sx={innerBoxStyle}>
                <Typography variant="body1" sx={itemStyle}>
                  Email
                </Typography>
                {data.email && (
                  <Typography variant="body1" sx={valueStyle}>
                    {data.email}
                  </Typography>
                )}
              </Box>
              <Box sx={innerBoxStyle}>
                <Typography variant="body1" sx={itemStyle}>
                  Phone Number
                </Typography>
                {data.phone_number && (
                  <Typography variant="body1" sx={valueStyle}>
                    {data.phone_number}
                  </Typography>
                )}
              </Box>
              <Box sx={innerBoxStyle}>
                <Typography variant="body1" sx={itemStyle}>
                  Created At
                </Typography>
                {data.createdAt && (
                  <Typography variant="body1" sx={valueStyle}>
                    {formateDate(data.createdAt)}
                  </Typography>
                )}
              </Box>
              <Box sx={innerBoxStyle}>
                <Typography variant="body1" sx={itemStyle}>
                  Last Login
                </Typography>
                {data.lastLogin && (
                  <Typography variant="body1" sx={valueStyle}>
                    {formateDate(data.lastLogin)}
                  </Typography>
                )}
              </Box>
              <Box sx={innerBoxStyle}>
                <Typography variant="body1" sx={itemStyle}>
                  Validated{" "}
                </Typography>
                {data.isValidated && (
                  <Typography variant="body1" sx={valueStyle}>
                    {formateString(data.isValidated)}
                  </Typography>
                )}
              </Box>
              <Box sx={innerBoxStyle}>
                <Typography variant="body1" sx={itemStyle}>
                  Vendor
                </Typography>
                {data.isVendor && (
                  <Typography variant="body1" sx={valueStyle}>
                    {formateString(data.isVendor)}
                  </Typography>
                )}
              </Box>
            </StyledCard>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default UserDetails;
