import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import logo from "../../assets/fapshi-logo.png";
import Commonbutton from "../../components/CommonButton";
import Commontextfield, {
  usePasswordToggle,
} from "../../components/CommonTextField";

import { useNavigate } from "react-router-dom";

import { colors } from "../../utils/DefaultStyle";
import AuthContext from "../../hooks/AuthContext";

export const gridStyle = {
  marginTop: { xs: "40%", sm: "10%" },
};

export const btnStyle = {
  fontSize: "0.9375rem",
};

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().email(),
  password: yup.string(),
});

const SigninForm = () => {
  const { login, errorMessage } = useContext(AuthContext);

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const navigate = useNavigate();

  const onHandleSubmit = async (values, props) => {
    const isLoggedIn = await login(values);
    props.setSubmitting(false);
    isLoggedIn && navigate("/admin");
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={gridStyle}
    >
      <Grid item display="contents" alignContent="center">
        <img
          src={logo}
          alt="fapshi logo"
          style={{ width: "90px", height: "50px", objectFit: "contain" }}
        />

        <h1 style={{ marginTop: "initial" }}> Sign in</h1>
      </Grid>
      <Grid item>
        <Formik
          onSubmit={onHandleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Typography
                className={errorMessage ? "errorMessage" : "offscreen"}
                aria-live="assertive"
                sx={{
                  fontWeight: 900,
                  fontSize: "1rem",
                  color: colors.errorcolor,
                  marginBottom: "1rem",
                }}
              >
                {errorMessage}
              </Typography>
              <Field
                as={Commontextfield}
                label="Email Address"
                name="email"
                type="email"
                fullWidth
                required
                onChange={props.handleChange}
                value={props.values.email}
              />
              <Field
                as={Commontextfield}
                label="Password"
                name="password"
                type={PasswordInputType}
                fullWidth
                required
                iconEnd={ToggleIcon}
                onChange={props.handleChange}
                value={props.values.password}
              />
              <Field
                as={Commonbutton}
                type="submit"
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                sx={btnStyle}
                btnText={
                  props.isSubmitting ? (
                    <CircularProgress
                      style={{ width: "20px", height: "20px", size: "0.5rem" }}
                    />
                  ) : (
                    "Sign in"
                  )
                }
                fullWidth
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
export default SigninForm;
