import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { colors } from "../utils/DefaultStyle";
import Commonbutton from "./CommonButton";

function UnclickableTable({ tableData, hasButton, handleClick, style }) {
  const { headers, rowData: rows } = tableData;
  return (
    <TableContainer
      sx={{
        position: "absolute",
      }}
    >
      <Table sx={[{ minWidth: 650, bgcolor: colors.tableColor }, style]}>
        <TableHead>
          <TableRow>
            {headers.map((h1, index) => (
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                {h1.header}
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((trow) => (
            <TableRow key={trow._id}>
              {headers.map((h1, index) => (
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "1rem",
                    padding: "12px",
                  }}
                  key={index}
                >
                  {trow[h1.field] ? trow[h1.field] : "-"}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  fontSize: "1rem",
                  padding: "12px",
                }}
              >
                {hasButton && (
                  <Commonbutton
                    variant="outlined"
                    color="secondary"
                    btnText={hasButton}
                    onClick={handleClick}
                    sx={{
                      color: colors.chocolateBlack,
                      borderRadius: 25,
                      width: "fit-content",
                      padding: "0.1rem 0.8rem",
                      height: "unset",
                      fontSize: "1rem",
                      fontWeight: 400,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UnclickableTable;
