import React, { useState } from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Grid, Container, Menu } from "@mui/material";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import Commontextfield, {
  usePasswordToggle,
} from "../../components/CommonTextField";
import Commonbutton from "../../components/CommonButton";
import Alerts from "../../components/Alerts";
import { btnStyle } from "../signinForm/SigninForm";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";

const passwordbtnStyle = {
  "&:hover": { backgroundColor: "rgb(63, 81, 181)" },
  height: "24px",
  display: "inherit",
  borderRadius: "30px",
  textTransform: "none",
  paddingLeft: "1rem",
  pr: "1rem",
  color: "#fff",
  float: "right",
  backgroundColor: "rgb(63, 81, 181)",
  fontSize: "14px",
  justifyContent: "center",
  whiteSpace: "nowrap",
};
const initialValues = {
  oldPass: "",
  newPass: "",
};

const validationSchema = yup.object().shape({
  oldPass: yup.string(),
  newPass: yup.string(),
});

const CHANGE_PASSWORD_URL = "/admin/change-password";
var success = null;

function ClearPassword() {
  const [response, errorMessage, loading, axiosFetch] = useAxios();
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = useState(false);
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();

  const onSubmit = async (values, props) => {
    success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: CHANGE_PASSWORD_URL,
      requestConfig: {
        ...values,
      },
    });
    if (success) {
      props.resetForm();
      setOpen(true);
    }
  };

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Commonbutton
        variant="contained"
        endIcon={<ExpandMoreOutlinedIcon />}
        sx={passwordbtnStyle}
        color="primary"
        btnText="Change password"
        onClick={(e) => setPassword(true)}
      />
      <Menu
        sx={{
          mt: "40px",
          paddingRight: "10rem",
          maxWidth: "60%",
        }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={password}
        onClose={(e) => setPassword(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Container>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ borderRadius: "20px" }}
          >
            <Grid item display="contents" alignContent="center">
              <h2>Clear Password</h2>
            </Grid>
            <Grid item>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <Form onSubmit={props.handleSubmit}>
                    <Field
                      as={Commontextfield}
                      label="Old Password"
                      name="oldPass"
                      type={PasswordInputType}
                      fullWidth
                      required
                      iconEnd={ToggleIcon}
                      onChange={props.handleChange}
                      value={props.values.oldPass}
                    />
                    <Field
                      as={Commontextfield}
                      label="New Password"
                      name="newPass"
                      type={PasswordInputType}
                      fullWidth
                      required
                      iconEnd={ToggleIcon}
                      onChange={props.handleChange}
                      value={props.values.newPass}
                    />
                    <Field
                      as={Commonbutton}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={btnStyle}
                      fullWidth
                      disabled={loading}
                      btnText={
                        loading ? (
                          <CircularProgress
                            style={{
                              width: "20px",
                              height: "20px",
                              size: "0.5rem",
                            }}
                          />
                        ) : (
                          "Reset"
                        )
                      }
                    />
                    {success && (
                      <Alerts
                        severity="success"
                        open={open}
                        onClose={onClose}
                        message={response.message}
                      />
                    )}

                    {errorMessage && (
                      <Alerts
                        severity="error"
                        open={open}
                        onClose={onClose}
                        message={errorMessage}
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </Menu>
    </>
  );
}

export default ClearPassword;
