import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Grid, Box } from "@mui/material";
import Commonbutton from "../../components/CommonButton";
import PlainCard from "../../components/PlainCard";
import {
  ButtonStyleDisplay,
  ButtonStyleDisplay2,
} from "../userActivation/AccountDetails";
import { firstName, capitalize, lowerCase } from "../../utils/helperFunctions";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";
import Confirmationmodal from "../../components/Confirmationmodal";
import Rejectmodal from "../../components/Rejectmodal";
import DetailsCard from "../../components/DetailsCard";
import Alerts from "../../components/Alerts";
import Spinner from "../../components/Spinner";

const BankDetailsPage = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [toSuccess, setToSuccess] = useState(false);

  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [description, setDescription] = useState("");

  const BANK_DETAILS_URL = `/pending-cashout/${_id}`;
  const CONFIRM_CASHOUT_URL = `/confirm-cashout/${_id}`;
  const CANCEL_CASHOUT_URL = `/cancel-cashout/${_id}`;
  const [response, errorMessage, loading, axiosFetch] = useAxios();

  const handleClose = () => {
    setOpenApprove(false);
    setOpenDecline(false);
  };

  const handleApproveClick = () => {
    setOpenApprove(true);
  };
  const handleDeclineClick = () => {
    setOpenDecline(true);
  };

  const handleApproveSubmit = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: CONFIRM_CASHOUT_URL,
    });
    if (success) {
      setToSuccess(true);
      navigate("/admin/pending-cashout");
    }
  };

  const handleDeclineSubmit = async () => {
    const success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: CANCEL_CASHOUT_URL,
      requestConfig: {
        description,
      },
    });
    if (success) {
      setToSuccess(true);
      navigate("/admin/pending-cashout");
    }
  };

  const getData = async () => {
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: BANK_DETAILS_URL,
    });

    return axiosFetch;
  };

  const data = response;

  useEffect(() => {
    getData();
  }, []);

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToSuccess(false);
    setOpen(false);
  };

  return (
    <>
      {loading && <Spinner text="Fetching data..." />}
      {toSuccess && (
        <Alerts
          severity="success"
          open={toSuccess}
          onClose={onClose}
          message={response.message}
        />
      )}
      {errorMessage && (
        <Alerts
          severity="error"
          open={open}
          onClose={onClose}
          message={errorMessage}
        />
      )}
      {
        <Grid container>
          <Grid container direction="row" spacing={4}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                marginTop: "2.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: {
                    xs: "center",
                  },
                  justifyContent: {
                    xs: "center",
                    md: "space-between",
                  },
                  width: {
                    xs: "100%",
                  },
                }}
              >
                {data.bankDetails && (
                  <h2
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    {firstName(
                      capitalize(lowerCase(data.bankDetails.ownerName))
                    )}
                    's Details
                  </h2>
                )}
              </Box>
              <Box sx={ButtonStyleDisplay}>
                <Commonbutton
                  variant="contained"
                  color="primary"
                  btnText="Approve"
                  sx={{ fontSize: "1rem", marginRight: "1.2rem" }}
                  onClick={handleApproveClick}
                />
                <Commonbutton
                  variant="outlined"
                  color="primary"
                  btnText="Decline"
                  sx={{ fontSize: "1rem" }}
                  onClick={handleDeclineClick}
                />
                <Confirmationmodal
                  handleSubmit={handleApproveSubmit}
                  open={openApprove}
                  handleClose={handleClose}
                  text={`This action will grant ${
                    data.bankDetails && data.bankDetails.ownerName
                  } the access to use bank account for payout. Are you sure you want to proceed?`}
                />
                <Rejectmodal
                  handleSubmit={handleDeclineSubmit}
                  open={openDecline}
                  handleClose={handleClose}
                  text={`This action will reject ${
                    data.bankDetails && data.bankDetails.ownerName
                  }  the access to use bank account for payout. Are you sure you want to proceed?`}
                  description={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} xs={12} direction="column">
              <DetailsCard
                transferId={data.transferId && data.transferId}
                amount={data.amount && data.amount}
                medium={data.medium && data.medium}
                status={data.status && data.status}
                accBalance={data.accBalance && data.accBalance}
                charges={data.charges && data.charges}
                createdAt={data.createdAt && data.createdAt}
                updatedAt={data.updatedAt && data.updatedAt}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              {data.bankDetails && (
                <PlainCard title="Bank Details" data={data.bankDetails} />
              )}
            </Grid>
            <Box sx={ButtonStyleDisplay2}>
              <Commonbutton
                variant="outlined"
                color="primary"
                btnText="Decline"
                sx={{ fontSize: "1rem", marginRight: "1.2rem" }}
                onClick={handleDeclineClick}
              />
              <Commonbutton
                variant="contained"
                color="primary"
                btnText="Approve"
                sx={{ fontSize: "1rem" }}
                onClick={handleApproveClick}
              />
            </Box>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default BankDetailsPage;
