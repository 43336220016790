import React, { useContext, useState } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import {
  Box,
  AppBar,
  CssBaseline,
  Drawer,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SideBar from "../../components/SideBar";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ClearPinButton from "../clearPin/ClearPinButton";
import ClearPassword from "../clearPassword/ClearPassword";
import { Person } from "@mui/icons-material";

import { firstName } from "../../utils/helperFunctions";

import BankDetails from "../bankPayoutRequest/BankDetails";
import BankTable from "../bankPayoutRequest/BankTable";
import CardRequest from "../cardPayment/CardRequest";
import CardRequestDetails from "../cardPayment/CardRequestDetails";
import UnsettledCardPayment from "../cardPayment/UnsettledCardPayment";
import AccountDetails from "../userActivation/AccountDetails";
import AccountVerificationRequest from "../userActivation/AccountVerificationRequest";
import AuthContext from "../../hooks/AuthContext";
import SearchBar from "../../components/SearchBar";
import UserDetails from "../user/UserDetails";
import { colors } from "../../utils/DefaultStyle";

const drawerWidth = 220;

function Layout(props) {
  const { logout, userInfo } = useContext(AuthContext);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileOpen(!mobileOpen, open);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <Grid sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: { xs: "6rem", sm: "5rem", md: "3rem" },
          background: colors.background,
          color: "#4B4B4B",
          boxShadow: 0,
        }}
      >
        <Toolbar
          sx={{
            display: "block",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingY: "0.8rem",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle()}
              sx={{
                mr: 2,
                display: { sm: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <ClearPinButton />
                <ClearPassword />
              </Box>

              <Box
                sx={{
                  fontSize: "14px",
                }}
              >
                <SearchBar />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "1.1rem",
                    flexDirection: "row",
                  }}
                >
                  {firstName(userInfo.username)}
                </Typography>

                <Person
                  sx={{
                    color: "#3f51b5",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(true)}
                />
                <Menu
                  sx={{ mt: "40px" }}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  open={open}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                    mt: "3rem",
                  }}
                >
                  <MenuItem onClick={handleLogout}>
                    <LogoutOutlinedIcon sx={{ color: "#3f51b5" }} />
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <ClearPinButton />
            <ClearPassword />
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box
            onClick={handleDrawerToggle(false)}
            onKeyDown={handleDrawerToggle(false)}
          >
            <SideBar />
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <SideBar />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 4,
          width: { sm: `calc(100% - ${drawerWidth}%)` },
          paddingY: { xs: "4rem", md: "2.5rem" },
        }}
      >
        <Outlet />

        <Routes>
          <Route
            path="/request-vendorInfo"
            element={<AccountVerificationRequest />}
          />

          <Route path="/request-vendorInfo/:_id" element={<AccountDetails />} />
          <Route path="/card-request" element={<CardRequest />} />
          <Route path="/card-request/:_id" element={<CardRequestDetails />} />
          <Route path="/payment" element={<UnsettledCardPayment />} />

          <Route path="/pending-cashout" element={<BankTable />} />
          <Route path="/pending-cashout/:_id" element={<BankDetails />} />
          <Route path="/getuser/:_id" element={<UserDetails />} />
        </Routes>
      </Box>
    </Grid>
  );
}

export default Layout;
