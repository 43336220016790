import axios from "axios";
const BASE_URL = "https://api.fapshi.com";
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
axiosInstance.interceptors.request.use( (config) => {
    let userinfo = JSON.parse(window.localStorage.getItem("user"));
    const accessToken = userinfo.token;
    config.headers.common["x-access-token"] = accessToken;

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;