import React, { useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Commonbutton from "../../components/CommonButton";
import { Menu } from "@mui/material";
import { Grid, Container } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { btnStyle } from "../signinForm/SigninForm";
import CircularProgress from "@mui/material/CircularProgress";
import Commontextfield, {
  usePasswordToggle,
} from "../../components/CommonTextField";

import Alerts from "../../components/Alerts";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";

const PIN_RESET_URL = "/reset-pin";

const initialValues = {
  email: "",
  supportPin: "",
};
const clearpinButtonStyle = {
  "&:hover": { backgroundColor: "#fff", color: "#3f51b5" },
  height: "24px",
  display: "inherit",
  borderRadius: "30px",
  textTransform: "none",
  paddingLeft: "1rem",
  pr: "1rem",
  color: "#000",
  backgroundColor: "#ffffff",
  boxShadow: "0 1.2px 5.68px 0 rgb(0 0 0 / 10%)",
  fontSize: "15px",
  justifyContent: "center",
  whiteSpace: "nowrap",
};

const validationSchema = yup.object().shape({
  email: yup.string().email(),
  supportPin: yup.string(),
});

var success = null;

function ClearPinButton() {
  const [response, errorMessage, loading, axiosFetch, result] = useAxios();

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();

  const [open, setOpen] = React.useState(false);
  const [pin, setPin] = useState(false);

  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onSubmit = async (values, props) => {
    success = await axiosFetch({
      axiosInstance: axiosInstance,
      method: "delete",
      url: PIN_RESET_URL,
      requestConfig: {
        data: { ...values },
      },
    });
    if (success) {
      props.resetForm();
      setOpen(true);
    }
  };

  return (
    <>
      <Commonbutton
        variant="contained"
        endIcon={<ExpandMoreOutlinedIcon />}
        sx={clearpinButtonStyle}
        color="inherit"
        btnText="Reset pin"
        onClick={(e) => setPin(true)}
      />
      <Menu
        sx={{
          mt: "40px",
          paddingRight: "10rem",
          width: "60%",
        }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={pin}
        onClose={(e) => setPin(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Container sx={{ boxShadow: "none" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ borderRadius: "20px" }}
          >
            <Grid item display="contents" alignContent="center">
              <h2>Reset User Pin</h2>
            </Grid>
            <Grid item>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <Form onSubmit={props.handleSubmit}>
                    <Field
                      as={Commontextfield}
                      label="Email Address"
                      name="email"
                      type="email"
                      fullWidth
                      required
                      onChange={props.handleChange}
                      value={props.values.email}
                    />
                    <Field
                      as={Commontextfield}
                      label="Support Pin"
                      name="supportPin"
                      type={PasswordInputType}
                      fullWidth
                      required
                      iconEnd={ToggleIcon}
                      onChange={props.handleChange}
                      value={props.values.supportPin}
                    />
                    <Field
                      as={Commonbutton}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={btnStyle}
                      fullWidth
                      disabled={loading}
                      btnText={
                        loading ? (
                          <CircularProgress
                            style={{
                              width: "20px",
                              height: "20px",
                              size: "0.5rem",
                            }}
                          />
                        ) : (
                          "Reset"
                        )
                      }
                    />
                    {success && (
                      <Alerts
                        severity="success"
                        open={open}
                        onClose={onClose}
                        message={response.message}
                      />
                    )}

                    {errorMessage && (
                      <Alerts
                        severity="error"
                        open={open}
                        onClose={onClose}
                        message={errorMessage}
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </Menu>
    </>
  );
}

export default ClearPinButton;
