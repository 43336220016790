import React, { useEffect } from "react";

import { Grid, Typography, Box } from "@mui/material";

import { tableStyle } from "../userActivation/AccountVerificationRequest";
import MyTable from "../../components/Table";
import useAxios from "../../hooks/useAxios";
import axiosInstance from "../../api/AxiosInstance";
import { iconStyle } from "../userActivation/AccountVerificationRequest";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import Spinner from "../../components/Spinner";
import { formateDate } from "../../utils/helperFunctions";

const CARD_REQUEST_URL = "/card-request";

const headers = [
  { header: "S/N", field: "sn" },
  { header: "Request Date", field: "merchRequestDate" },
  { header: "Company Name", field: "companyName" },
];

function CardRequest() {
  const [response, errorMessage, loading, axiosFetch] = useAxios();
  const getData = async () => {
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "get",
      url: CARD_REQUEST_URL,
    });

    return axiosFetch;
  };

  useEffect(() => {
    getData();
  }, []);
  const rows = response;
  const rowData = [];
  rows.map((row, index) => {
    if (Object.hasOwn(row, "legalFufill")) {
      row["companyName"] = row.legalFufill.companyName;
    }

    row["merchRequestDate"] = `${formateDate(row.merchRequestDate)}`;

    row["sn"] = index + 1;
    rowData.push(row);
  });

  const tableData = { headers, rowData };
  return (
    <Grid container direction="column" sx={{ paddingY: "2.5rem" }}>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "left",
          width: "100%",
        }}
      >
        <Typography
          align="left"
          variant="h5"
          component="h5"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          International Payment Requests
        </Typography>
      </Grid>
      <Grid item>
        {loading && <Spinner text="Fetching verfication request..." />}
        {response.length > 0 ? (
          <Box sx={{ position: "relative" }}>
            <MyTable
              tableData={tableData}
              link="/admin/card-request"
              style={tableStyle}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              marginTop: "20%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CreditCardOutlinedIcon sx={iconStyle} />

            <Typography
              component="h6"
              variant="h6"
              style={{
                textAlign: "center",
                alignContent: "center",
                fontWeight: "600",
                fontSize: "1.4rem",
              }}
            >
              No available request
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default CardRequest;
