import React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { CardHeader, Box, Typography } from "@mui/material";

import { capitalize, addSpace, lowerCase } from "../utils/helperFunctions";
import { colors } from "../utils/DefaultStyle";

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    padding: "1rem",
    borderRadius: "0.5rem",
    backgroundColor: "#fefefe",
    boxShadow: 5,
  })
);

export const headerStyle = {
  margin: 0,
  padding: 0,
};

export const headerTextStyle = {
  marginBottom: "0.5rem",
  paddingBottom: "0.5rem",
  fontSize: "1.2rem",
  borderBottom: "1px solid #eeeeee",
  fontWeight: "bold",
};

export const innerBoxStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingY: "0.3rem",
};

export const itemStyle = {
  fontSize: "0.9rem",
  fontWeight: "normal",
  color: colors.chocolateBlack,
};

export const valueStyle = {
  maxWidth: "70%",
  textAlign: "right",
  fontSize: "0.9rem",
};

function PlainCard(props) {
  const { title, data } = props;
  return (
    <StyledCard elevation={2}>
      {title && (
        <CardHeader
          sx={headerStyle}
          title={
            <Typography variant="body1" sx={headerTextStyle}>
              {title}
            </Typography>
          }
        />
      )}

      <Box>
        {Object.entries(data).map((item) => {
          return (
            <Box sx={innerBoxStyle}>
              <Typography variant="body1" sx={itemStyle}>
                {capitalize(addSpace(item[0]))}
              </Typography>
              <Typography variant="body1" sx={valueStyle}>
                {typeof item[1] === "string"
                  ? capitalize(lowerCase(item[1]))
                  : item[1]}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </StyledCard>
  );
}

export default PlainCard;
