export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lowerCase = (str) => {
  return str.toLowerCase();
};

export const firstName = (str) => {
  return str.split(" ")[0];
};

export const addSpace = (str) => {
  return str.replace(/[A-Z]/g, " $&").trim();
};

export const formatAmount = (amount) => {
  return amount.toLocaleString("en-GB", {
    style: "currency",
    currency: "XAF",
  });
};
export const formateDate = (time) => {
  return new Date(time).toLocaleString();
};

export const formateString = (str) => {
  return str.toString();
};
