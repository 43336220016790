import React, { useState, useEffect, useRef } from "react";

import Inputbase from "./Inputbase";
import Searchmodal from "./Searchmodal";
import useAxios from "../hooks/useAxios";
import axiosInstance from "../api/AxiosInstance";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  width: "100%",
  justifyContent: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SEARCH_URL = "/finduser";

const SearchBar = () => {
  const [response, errorMessage, loading, axiosFetch] = useAxios();
  const [query, setQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const inputRef = useRef();

  const handleOpen = () => setOpen(!open);
  const handleClose = () => {
    setOpen(false);
    setQuery("");
  };

  const getUsers = async () => {
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "post",
      url: SEARCH_URL,
      requestConfig: {
        query,
      },
    });
  };

  useEffect(() => {
    if (query.length > 2) {
      getUsers();
    }
  }, [query]);

  const usersData = response;

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Inputbase
          placeholder="Find user…"
          inputProps={{ "aria-label": "Search" }}
          name="query"
          onChange={(event) => {
            getUsers(event.target.value);
            setQuery(event.target.value);
          }}
          value={query}
          ref={inputRef}
          onClick={handleOpen}
        />
      </Search>
      {open && (
        <Searchmodal
          userlink="/admin/getuser"
          usersData={usersData}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default SearchBar;
