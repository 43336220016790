import React, { useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Layout from "../Pages/layout/Layout";
import SigninForm from "../Pages/signinForm/SigninForm";

import AuthContext from "../hooks/AuthContext";
import RequireAuth from "../hooks/RequireAuth";

import AccountVerificationRequest from "../Pages/userActivation/AccountVerificationRequest";
function AllRoutes() {
  const { checkUser } = useContext(AuthContext);
  const location = useLocation();

  if (checkUser === true) {
    <Navigate to="/home/*" state={{ from: location }} repalce />;
  } else {
    <Navigate to="/" state={{ from: location }} repalce />;
  }

  return (
    <Routes>
      <Route path="/" element={<SigninForm />} />

      <Route element={<RequireAuth />}>
        <Route path="/admin/*" element={<Layout />}>
          <Route path="/admin/*" element={<AccountVerificationRequest />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AllRoutes;
