import React from "react";
import { CardMedia, Box } from "@mui/material";
import styled from "@emotion/styled";
import { StyledCard } from "./PlainCard";
import Modal from "@mui/material/Modal";

import PrismaZoom from "react-prismazoom";

const StyledMediaCard = styled((props) => <CardMedia {...props} />)(
  ({ theme }) => ({
    height: "12.75rem",
    width: "20.22rem",
    cursor: "pointer",
    backgroundColor: "#fcfcfc",
    borderRadius: "0.5rem",
    boxShadow: 3,
  })
);
export const styleDisplay = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 10,
  p: 0,
  marginTop: 0,
  marginButtom: 0,
};

function MediaCard(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { img } = props;

  return (
    <>
      <StyledMediaCard
        elevation="2"
        component="img"
        height="120"
        image={img}
        alt="Id Card"
        sx={{ width: { xs: "20.22rem", md: "100%" } }}
        onClick={handleOpen}
      />
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleDisplay}>
          <PrismaZoom>
            <img src={img} alt="no image" width="100%" height="100%" />
          </PrismaZoom>
        </Box>
      </Modal>
    </>
  );
}

export default MediaCard;
